"use client";

import { motion, useMotionValue, useTransform, animate, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

export default function Counter({ number }) {
  const count = useMotionValue(number >= 100 ? number / 2 : 0);
  const rounded = useTransform(count, Math.round);
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (!isInView) return;

    const animation = animate(count, number, { duration: 3 });

    return animation.stop;
  }, [isInView]);

  return <motion.span ref={ref}>{rounded}</motion.span>;
}

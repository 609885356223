"use client";

import { AnimatePresence, motion, useInView } from "framer-motion";
import Image from "next/image";
import { useState, useEffect, useRef } from "react";

const allLogos = [
  "/logos/vw.webp",
  "/logos/audi.webp",
  "/logos/citroen.webp",
  "/logos/dacia.webp",
  "/logos/fiat.webp",
  "/logos/ford.webp",
  "/logos/honda.webp",
  "/logos/hyundai.webp",
  "/logos/kia.webp",
  "/logos/mazda.webp",
  "/logos/mercedes.webp",
  "/logos/nissan.webp",
  "/logos/opel.webp",
  "/logos/peugeot.webp",
  "/logos/renault.webp",
  "/logos/seat.webp",
  "/logos/skoda.webp",
  "/logos/suzuki.webp",
  "/logos/volvo.webp",
];

export default function BrandsGrid() {
  const [currentLogos, setCurrentLogos] = useState(allLogos.slice(0, 5));
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (!isInView) return;

    const interval = setInterval(() => {
      setCurrentLogos((prev) => {
        const tilesToChange = Math.min(3, Math.floor(Math.random() * 3) + 1); // Losuj od 1 do 3
        const availableLogos = allLogos.filter((logo) => !prev.includes(logo));

        // Losowanie indeksów do zmiany
        const indicesToChange = [];
        while (indicesToChange.length < tilesToChange) {
          const randomIndex = Math.floor(Math.random() * prev.length);
          if (!indicesToChange.includes(randomIndex)) {
            indicesToChange.push(randomIndex);
          }
        }

        // Tworzenie nowej listy logo
        const newLogos = [...prev];
        indicesToChange.forEach((index) => {
          const randomLogoIndex = Math.floor(Math.random() * availableLogos.length);
          newLogos[index] = availableLogos[randomLogoIndex];
          availableLogos.splice(randomLogoIndex, 1); // Usuń użyte logo
        });

        return newLogos;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [isInView]);

  return (
    <div ref={ref} className="grid w-full grid-cols-3 gap-2 sm:grid-cols-5 sm:gap-6 lg:grid-cols-3">
      {currentLogos.map((logo, i) => (
        <div key={i} className="relative flex aspect-square h-auto w-full items-center justify-center overflow-hidden bg-neutral-200">
          <AnimatePresence>
            <motion.div
              key={`logo-${logo}-${i}`}
              initial={{ x: "150%", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "-150%", opacity: 0 }}
              transition={{
                type: "tween",
                duration: 0.8,
                ease: "easeInOut",
                staggerChildren: 0.2,
              }}
              className="absolute flex h-full w-full items-center justify-center p-5"
            >
              <Image alt={`Logo ${i}`} src={logo} width={100} height={100} />
            </motion.div>
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
}
